<template>
  <b-card-text>
    <div class="card">
      <div class="card-body">
        <div>
          <h4 class="card-title">Adicionar Menu</h4>
          <hr />
          <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-12 col-xl-6 mb-2">
              <label>Tipo</label>
              <multiselect
                :options="options_tipo"
                class="helo"
                v-model="form.type"
                placeholder="Tipo"
                select-label="Selecionar"
                deselect-label="Remover"
                :allow-empty="false"
              ></multiselect>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-12 col-xl-6 mb-2">
              <label>{{ form.type }}</label>
              <multiselect
                v-model="category"
                :options="options_categorys"
                class="helo"
                label="name"
                placeholder="Categoria"
                select-label="Selecionar"
                deselect-label="Remover"
                :loading="isLoading"
                @search-change="searchCategory"
                v-if="form.type == 'Categoria'"
              ></multiselect>
              <multiselect
                v-else-if="form.type == 'Página'"
                v-model="page"
                :options="options_pages"
                class="helo"
                label="title"
                placeholder="Página"
                select-label="Selecionar"
                deselect-label="Remover"
                :loading="isLoadingPage"
                @search-change="searchPage"
              ></multiselect>
              <b-form-group v-else-if="form.type == 'Produtos'">
                <b-form-input
                  placeholder="Produtos"
                  for="text"
                  value="Produtos"
                  disabled
                  class="bg-light"
                ></b-form-input>
              </b-form-group>
              <b-form-group v-else-if="form.type == 'Produtos Destaque'">
                <b-form-input
                  placeholder="Produtos Destaque"
                  for="text"
                  value="Produtos Destaque"
                  disabled
                  class="bg-light"
                ></b-form-input>
              </b-form-group>
              <b-form-group v-else-if="form.type == 'Início'">
                <b-form-input
                  placeholder="Página Inicial"
                  for="text"
                  value="Página Inicial"
                  disabled
                  class="bg-light"
                ></b-form-input>
              </b-form-group>
              <b-form-group v-else>
                <b-form-input
                  placeholder="Link"
                  for="text"
                  v-model="form.link"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-12 col-xl-6 mb-2">
              <label>Nome</label>
              <b-form-group>
                <b-form-input
                  placeholder="Nome do link..."
                  for="text"
                  v-model="form.name"
                  :class="{ 'is-invalid': form && $v.form.name.$error }"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-12 col-xl-4 mb-2">
              <label>Ordem</label>
              <b-form-group>
                <b-form-input
                  placeholder="Ordem..."
                  for="number"
                  type="number"
                  v-model.number="form.ordem"
                  min="0"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-12 col-xl-2 mb-2">
              <label>Adicionar</label>
              <b-button
                variant="info"
                class="w-100"
                @click.prevent="salvarDados()"
              >
                <i class="bx bx-plus font-size-16 align-middle mr-2"></i>
                Adicionar
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card" v-if="loadingMenu">
      <div class="card-body">
        <div class="text-center text-info my-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </div>
    </div>
    <div class="card" v-else-if="errorMenu">
      <div class="card-body">
        <div class="text-center text-danger my-2">
          <span class="mb-2 d-block">Erro ao carregar dados!</span>
        </div>
      </div>
    </div>
    <div class="row" v-else-if="menus.length <= 0">
      <div class="col-12">
        <div class="card">
          <div class="card-body py-5 text-center">
            <h5 class="mb-3">Nenhum item encontrado!</h5>
            <img width="250" src="@/assets/images/not-data.svg" />
          </div>
        </div>
      </div>
    </div>
    <div class="card" v-else>
      <div class="card-body">
        <div>
          <h4 class="card-title">Menu</h4>
          <hr />
          <div class="row">
            <div class="col-12">
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">Tipo</th>
                      <th scope="col">Link</th>
                      <th scope="col">Nome</th>
                      <th scope="col">Ordem</th>
                      <th scope="col" class="text-right">Ação</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      class="align-middle"
                      v-for="(i, index) in menus"
                      :key="index"
                    >
                      <td class="align-middle">
                        {{ i.type }}
                      </td>
                      <td class="align-middle" v-if="i.page_id > 0">
                        {{ i.page.slug }}
                      </td>
                      <td class="align-middle" v-else-if="i.category_id > 0">
                        {{ i.category.slug }}
                      </td>
                      <td class="align-middle" v-else>
                        {{ i.link }}
                      </td>
                      <td class="align-middle">
                        {{ i.name }}
                      </td>
                      <td class="align-middle">
                        {{ i.ordem }}
                      </td>
                      <td class="align-middle text-right">
                        <!--<b-button variant="info">
                            <i class="bx bx-edit font-size-16 align-middle"></i>
                          </b-button>-->
                        <b-button
                          variant="danger"
                          class="ml-2"
                          @click.prevent="removerMenu(i)"
                        >
                          <i class="bx bx-trash font-size-16 align-middle"></i>
                        </b-button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-card-text>
</template>
<script>
import Multiselect from 'vue-multiselect'
import { mapActions } from 'vuex'
import { required } from 'vuelidate/lib/validators'
export default {
  props: {
    position: {
      type: String,
      default: 'Menu Principal',
    },
  },
  components: {
    Multiselect,
  },
  data() {
    return {
      menus: [],
      options_tipo: ['Link', 'Início', 'Página', 'Categoria', 'Produtos', 'Produtos Destaque'],
      form: {
        type: 'Link',
        position: 'Menu Principal',
      },
      options_pages: [],
      options_categorys: [],
      isLoading: true,
      isLoadingPage: true,
      filtro: {
        status: 0,
        limit: 10,
      },
      filtroPage: {
        status: 0,
        limit: 10,
      },
      filtroMenu: {},
      category: null,
      page: null,
      isLoadingMenu: true,
      loadingMenu: true,
      errorMenu: false,
    }
  },
  validations: {
    form: {
      name: { required },
    },
  },
  watch: {
    'form.type': function () {
      this.page = null
      this.category = null
      if (this.form.type == 'Categoria') {
        this.listCategory()
      }
      if (this.form.type == 'Página') {
        this.listPage()
      }
    },
    category: function () {
      if (this.category != null) {
        this.form.name = this.category.name
      }
      this.page = null
    },
    page: function () {
      if (this.page != null) {
        this.form.name = this.page.title
      }
      this.category = null
    },
  },
  created() {
    this.listMenu()
    this.form.position = this.position
  },
  methods: {
    ...mapActions('category', ['ActionCategoryAll']),
    ...mapActions('page', ['ActionPageAll']),
    ...mapActions('appearance', [
      'ActionMenuAll',
      'ActionMenuCreate',
      'ActionMenuDelete',
    ]),
    removerMenu(data) {
      this.$swal({
        icon: 'warning',
        title: 'Confirmação',
        text: 'Deseja Remover Este Item?',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `SIM`,
        denyButtonText: `NÃO`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.removerItemUp(data)
        }
      })
    },
    async removerItemUp(data) {
      try {
        this.$swal({
          title: 'Aguarde!',
          text: 'Removendo item',
          icon: 'info',
          showConfirmButton: false,
        })
        var dados = {
          data: data,
          id: data.id,
        }
        await this.ActionMenuDelete(dados)
        this.$swal({
          title: 'Sucesso',
          text: 'Ação realizada com sucesso!',
          icon: 'success',
          timer: 2000,
          timerProgressBar: true,
        })
        this.menus.splice(this.menus.indexOf(data), 1)
      } catch (e) {
        this.$swal({
          title: 'Erro!',
          text: e.body.message,
          icon: 'error',
          timer: 2000,
          timerProgressBar: true,
        })
      }
    },
    salvarDados() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.submitMenu()
      }
    },
    async submitMenu() {
      try {
        if (this.form.type == 'Categoria' && this.category != null) {
          this.form.category_id = this.category.id
        }
        if (this.form.type == 'Página' && this.page != null) {
          this.form.page_id = this.page.id
        }
        await this.ActionMenuCreate(this.form).then((res) => {
          res.data.data.category = this.category
          res.data.data.page = this.page
          this.menus.push(res.data.data)
          this.form = {
            type: 'Link',
            position: this.position
          }
          this.$v.$reset()
        })
        this.$swal({
          title: 'Sucesso',
          text: 'Ação realizada com sucesso!',
          icon: 'success',
          timer: 2000,
          timerProgressBar: true,
        })
      } catch (e) {
        this.$swal({
          title: 'Erro!',
          text: e.body.message,
          icon: 'error',
          timer: 2000,
          timerProgressBar: true,
        })
      }
    },
    async listMenu() {
      try {
        this.loadingMenu = true
        this.filtroMenu.position = this.position
        await this.ActionMenuAll(this.filtroMenu).then((res) => {
          this.menus = res.data.data != null ? res.data.data : []
          this.loadingMenu = false
          this.errorMenu = false
        })
      } catch (e) {
        this.menus = []
        this.loadingMenu = false
        this.errorMenu = true
      }
    },
    async listCategory() {
      try {
        this.isLoading = true
        await this.ActionCategoryAll(this.filtro).then((res) => {
          this.options_categorys = res.data.data.list
          this.isLoading = false
        })
      } catch (_) {
        this.options_categorys = []
      }
    },
    searchCategory(e) {
      this.filtro.search = e
      this.listCategory()
    },
    async listPage() {
      try {
        this.isLoadingPage = true
        await this.ActionPageAll(this.filtroPage).then((res) => {
          this.options_pages = res.data.data.list
          this.isLoadingPage = false
        })
      } catch (_) {
        this.options_pages = []
      }
    },
    searchPage(e) {
      this.filtroPage.title = e
      this.listPage()
    },
  },
}
</script>
