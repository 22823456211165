<template>
  <div>
    <b-card-text>
      <div class="card">
        <div class="card-body">
          <div>
            <h4 class="card-title">Produtos em Destaque</h4>
            <hr />
            <div class="row">
              <div class="col-lg-12 col-xl-7 mb-3">
                <label>Produto</label>
                <multiselect
                  placeholder="Pesquisar Produto"
                  label="title"
                  :options="products"
                  :custom-label="customLabel"
                  :loading="isLoading"
                  select-label="Selecionar"
                  deselect-label="Remover"
                  @search-change="searchProduct"
                  v-model="form.product_id"
                  :class="{ 'is-invalid': form && $v.form.product_id.$error }"
                >
                  <template slot="singleLabel" slot-scope="props">
                    <div class="d-flex">
                      <div class="mr-3">
                        <span v-for="img in props.option.image" :key="img.id">
                          <img
                            :src="img.image300"
                            v-if="img.capa == 1"
                            alt=""
                            class="avatar-md h-auto d-block rounded"
                          />
                        </span>
                      </div>
                      <div class="align-self-center overflow-hidden mr-auto">
                        <div>
                          <h5 class="font-size-14 text-truncate">
                            <a href="#" class="text-dark">
                              {{ props.option.name }}
                            </a>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template slot="option" slot-scope="props">
                    <div class="d-flex">
                      <div class="mr-3">
                        <span v-for="img in props.option.image" :key="img.id">
                          <img
                            :src="img.image300"
                            v-if="img.capa == 1"
                            alt=""
                            class="avatar-md h-auto d-block rounded"
                          />
                        </span>
                      </div>

                      <div class="align-self-center overflow-hidden mr-auto">
                        <div>
                          <h5 class="font-size-14 text-truncate">
                            <a href="#" class="text-dark">
                              {{ props.option.name }}
                            </a>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </template>
                </multiselect>
              </div>
              <div class="col-lg-12 col-xl-3">
                <label>Ordem</label>
                <b-form-group>
                  <b-form-input
                    placeholder="Ordem"
                    for="number"
                    type="number"
                    v-model.number="form.ordem"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-lg-12 col-xl-2">
                <label>Adicionar</label>
                <b-button
                  variant="info"
                  class="w-100"
                  @click.prevent="salvarDados()"
                >
                  <i class="bx bx-plus font-size-16 align-middle mr-2"></i>
                  Adicionar
                </b-button>
              </div>
              <div class="col-12">
                <hr />
              </div>
              <div class="card w-100 text-center" v-if="loading">
                <div class="card-body">
                  <div class="text-center text-info my-2">
                    <b-spinner class="align-middle"></b-spinner>
                  </div>
                </div>
              </div>
              <div class="card w-100 text-center" v-else-if="error">
                <div class="card-body">
                  <div class="text-center text-danger my-2">
                    <span class="mb-2 d-block">Erro ao carregar dados!</span>
                  </div>
                </div>
              </div>
              <div
                class="card w-100 text-center"
                v-else-if="produts.length <= 0"
              >
                <div class="card-body py-5 text-center">
                  <h5 class="mb-3">Nenhum item encontrado!</h5>
                  <img width="250" src="@/assets/images/not-data.svg" />
                </div>
              </div>
              <div class="col-12">
                <div class="row">
                  <div
                    v-for="(data, index) in produts"
                    :key="index"
                    class="col-xl-4 col-lg-6 col-sm-6"
                  >
                    <div class="card border">
                      <div class="card-body">
                        <div
                          class="product-img position-relative"
                          v-if="
                            data.product.image != null &&
                            data.product.image != '' &&
                            data.product.image.length > 0
                          "
                        >
                          <span
                            v-for="img in data.product.image"
                            :key="img.id"
                            class="w-100"
                          >
                            <img
                              :src="img.image300"
                              v-if="img.capa == 1"
                              loading="lazy"
                              class="img-fluid mx-auto d-block"
                              style="max-height: 100px !important"
                            />
                          </span>
                        </div>
                        <div class="product-img position-relative" v-else>
                          <img
                            src="@/assets/images/notpro.jpg"
                            alt
                            loading="lazy"
                            class="img-fluid mx-auto d-block"
                            style="max-height: 100px !important"
                          />
                        </div>
                        <div class="mt-4 text-center">
                          <h5 class="mb-3 text-truncate">
                            {{ data.product.name }}
                          </h5>
                          <p class="text-muted">
                            <i
                              class="fas fa-star text-warning mr-1"
                              v-for="index in data.product.start"
                              :key="index"
                            ></i>
                            <i
                              class="fas fa-star text-muted mr-1"
                              v-for="index in (5 - data.product.start)"
                              :key="index + data.product.start"
                            ></i>
                          </p>

                          <h5
                            class="my-0"
                            v-if="
                              dataPromo(
                                data.product.discount_date_start,
                                data.product.discount_date_end,
                              )
                            "
                          >
                            <span
                              class="text-muted mr-2"
                              v-if="data.product.discount_price > 0"
                            >
                              <del>R$ {{ data.product.price }}</del>
                            </span>
                            <b>R$ {{ data.product.discount_price }}</b>
                          </h5>
                          <h5 class="my-0" v-else>
                            <b>R$ {{ data.product.price }}</b>
                          </h5>
                        </div>
                        <div class="mt-4 text-center">
                          <div class="row">
                            <div class="col-8">
                              <b-form-group>
                                <b-form-input
                                  placeholder="Ordem..."
                                  for="number"
                                  type="number"
                                  class="text-center"
                                  min="0"
                                  @change="alterarOrdem(data)"
                                  v-model.number="data.ordem"
                                ></b-form-input>
                              </b-form-group>
                            </div>
                            <div class="col-4">
                              <b-button
                                variant="danger"
                                v-b-tooltip.hover
                                title="Remover Produto"
                                class="w-100"
                                @click.prevent="removerMenu(data)"
                              >
                                <i class="fas fa-trash"></i>
                              </b-button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-card-text>
  </div>
</template>
<style>
.is-invalid .multiselect__tags {
  border-color: #f46a6a !important;
  border: 1px solid #f46a6a !important;
}
</style>
<script>
import Multiselect from 'vue-multiselect'
import { mapActions } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import VsToast from '@vuesimple/vs-toast';
export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      products: [],
      produts: [],
      form: {
        product_id: null,
        ordem: null,
      },
      isLoading: true,
      filtro: {
        status: 0,
        limit: 10,
      },
      loading: true,
      error: false,
    }
  },
  validations: {
    form: {
      product_id: { required },
    },
  },
  created() {
    this.listProduct()
    this.listFeatured()
  },
  methods: {
    ...mapActions('product', ['ActionProductAll']),
    ...mapActions('appearance', [
      'ActionFeaturedCreate',
      'ActionFeaturedAll',
      'ActionFeaturedUpdate',
      'ActionFeaturedDelete',
    ]),
    customLabel({ name }) {
      return `${name}`
    },
    salvarDados() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.submitFeatured()
      }
    },
    async submitFeatured() {
      try {
        this.form.product_id = this.form.product_id.id
        await this.ActionFeaturedCreate(this.form).then((res) => {
          this.listFeatured()
          this.form.product_id = null
          this.form.ordem = null
          this.$v.$reset()
          this.$swal({
            title: 'Sucesso',
            text: 'Ação realizada com sucesso!',
            icon: 'success',
            timer: 2000,
            timerProgressBar: true,
          })
        })
      } catch (e) {
        this.$swal({
          title: 'Erro!',
          text: e.body.message,
          icon: 'error',
          timer: 2000,
          timerProgressBar: true,
        })
      }
    },
    async listProduct() {
      try {
        this.isLoading = true
        await this.ActionProductAll(this.filtro).then((res) => {
          this.products = res.data.data.list
          this.isLoading = false
        })
      } catch (_) {
        this.products = []
      }
    },
    searchProduct(e) {
      this.filtro.search = e
      this.listProduct()
    },
    async listFeatured() {
      try {
        this.loading = true
        await this.ActionFeaturedAll().then((res) => {
          this.produts = res.data.data
          this.loading = false
          this.error = false
        })
      } catch (_) {
        this.produts = []
        this.loading = false
        this.error = true
      }
    },
    async alterarOrdem(data) {
      try {
        data.product_id = data.product.id
        await this.ActionFeaturedUpdate(data)
         VsToast.show({
          title: 'Sucesso',
          message: 'Atualizado com sucesso!',
          variant: 'success',
          position: 'bottom-right'
        });
      } catch (_) {
         VsToast.show({
          title: 'Erro',
          message: 'Não foi possível atualizar a ordem!',
          variant: 'error',
          position: 'bottom-right'
        });
      }
    },
    removerMenu(data) {
      this.$swal({
        icon: 'warning',
        title: 'Confirmação',
        text: 'Deseja Remover Este Item?',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `SIM`,
        denyButtonText: `NÃO`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.removerItemUp(data)
        }
      })
    },
    async removerItemUp(data) {
      try {
        this.$swal({
          title: 'Aguarde!',
          text: 'Removendo item',
          icon: 'info',
          showConfirmButton: false,
        })
        var dados = {
          data: data,
          id: data.id,
        }
        await this.ActionFeaturedDelete(dados)
        this.$swal({
          title: 'Sucesso',
          text: 'Ação realizada com sucesso!',
          icon: 'success',
          timer: 2000,
          timerProgressBar: true,
        })
        this.produts.splice(this.produts.indexOf(data), 1)
      } catch (e) {
        this.$swal({
          title: 'Erro!',
          text: e.body.message,
          icon: 'error',
          timer: 2000,
          timerProgressBar: true,
        })
      }
    },
    dataPromo(start, end) {
      var dia = new Date()
      var d = new Date(start)
      var df = new Date(end)
      if (d <= dia) {
        if (dia <= df) {
          return true
        }
        return false
      }
    },
  },
}
</script>
