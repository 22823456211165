<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between"
        >
          <h4 class="mb-0 font-size-18">Aparência</h4>
        </div>
      </div>
    </div>
    <div class="row text-left checkout-tabs">
      <div class="col-12">
        <b-tabs
          pills
          vertical
          nav-class="p-0"
          nav-wrapper-class="col-lg-3 w-100"
        >
          <b-tab active>
            <template v-slot:title>
              <i class="fas fa-store check-nav-icon mt-3 mb-2"></i>
              <p class="font-weight-bold mb-3">Dados Gerais</p>
            </template>
            <GeneralData />
          </b-tab>
          <b-tab>
            <template v-slot:title>
              <i class="fas fa-bars d-block check-nav-icon mt-3 mb-2"></i>
              <p class="font-weight-bold mb-3">Menu</p>
            </template>
            <Menu />
          </b-tab>
          <b-tab>
            <template v-slot:title>
              <i class="fas fa-tags d-block check-nav-icon mt-3 mb-2"></i>
              <p class="font-weight-bold mb-3">Destaque</p>
            </template>
            <Featured/>
          </b-tab>
          <b-tab>
            <template v-slot:title>
              <i class="fas fa-bullhorn d-block check-nav-icon mt-3 mb-2"></i>
              <p class="font-weight-bold mb-3">Categorias/Destaque</p>
            </template>
            <FeaturedCategory/>
          </b-tab>
          <b-tab>
            <template v-slot:title>
              <i class="fas fa-sliders-h d-block check-nav-icon mt-3 mb-2"></i>
              <p class="font-weight-bold mb-3">Slider</p>
            </template>
            <Slider/>
          </b-tab>
          <b-tab>
            <template v-slot:title>
              <i class="fas fa-clock d-block check-nav-icon mt-3 mb-2"></i>
              <p class="font-weight-bold mb-3">Countdown</p>
            </template>
            <Countdown/>
          </b-tab>
          <b-tab>
            <template v-slot:title>
              <i class="fas fa-id-card d-block check-nav-icon mt-3 mb-2"></i>
              <p class="font-weight-bold mb-3">Informações de Contato</p>
            </template>
            <ContactInfo/>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>
<style>
.check-nav-icon {
  font-size: 20px !important;
}

.checkout-tabs .linkmenus .nav-pills .nav-link {
  background-color: #f5f5f5;
  margin-right: 10px;
}
.checkout-tabs .linkmenus .nav-pills .nav-link.active {
  background-color: #556ee6;
}
</style>
<script>
import GeneralData from './components/GeneralData'
import Menu from './components/Menu'
import Featured from './components/Featured'
import FeaturedCategory from './components/FeaturedCategory'
import Slider from './components/Slider'
import ContactInfo from './components/ContactInfo'
import Countdown from './components/Countdown'

export default {
  components: {
    GeneralData,
    Menu,
    Featured,
    Slider,
    ContactInfo,
    Countdown,
    FeaturedCategory
  },
}
</script>
