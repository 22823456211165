<template>
  <div>
    <b-card-text>
      <b-tabs pills fill nav-class="p-0" nav-wrapper-class="w-100">
        <b-tab active>
          <template v-slot:title>
            <p class="font-weight-bold p-0 m-0 mx-3 my-2">Menu Principal</p>
          </template>
          <MenuPrincipal />
        </b-tab>
        <b-tab>
          <template v-slot:title>
            <p class="font-weight-bold p-0 m-0 mx-3 my-2">
              Menu Rodapé (Bloco 1)
            </p>
          </template>
          <MenuPrincipal position="Menu Rodapé Bloco 1" />
        </b-tab>
        <b-tab>
          <template v-slot:title>
            <p class="font-weight-bold p-0 m-0 mx-3 my-2">
              Menu Rodapé (Bloco 2)
            </p>
          </template>
          <MenuPrincipal position="Menu Rodapé Bloco 2" />
        </b-tab>
      </b-tabs>
    </b-card-text>
  </div>
</template>
<script>
import MenuPrincipal from './MenuPrincipal'
export default {
  components: {
    MenuPrincipal,
  },
  data() {
    return {}
  },
}
</script>
