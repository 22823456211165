<template>
  <div>
    <b-card-text>
      <div class="card w-100 text-center" v-if="loading">
        <div class="card-body">
          <div class="text-center text-info my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </div>
      </div>
      <div class="card w-100 text-center" v-else-if="error">
        <div class="card-body">
          <div class="text-center text-danger my-2">
            <span class="mb-2 d-block">Erro ao carregar dados!</span>
          </div>
        </div>
      </div>
      <div class="card w-100 text-center" v-else-if="info == null">
        <div class="card-body py-5 text-center">
          <h5 class="mb-3">Nenhum item encontrado!</h5>
          <img width="250" src="@/assets/images/not-data.svg" />
        </div>
      </div>
      <div class="card" v-else>
        <div class="card-body">
          <div>
            <h4 class="card-title">Informações de Contato</h4>
            <hr />
            <div class="row">
              <div class="col-lg-12 col-xl-6 mb-1">
                <label>E-mail</label>
                <b-form-group>
                  <b-form-input
                    placeholder="E-mail para contato"
                    for="text"
                    v-model="info.email"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-lg-12 col-xl-6 mb-1">
                <label>Telefone</label>
                <b-form-group>
                  <b-form-input
                    placeholder="Telefone para contato"
                    for="text"
                    v-model="info.phone"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-lg-12 mb-1">
                <label>Endereço</label>
                <b-form-group>
                  <b-form-input
                    placeholder="Endereço"
                    for="text"
                    v-model="info.address"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-lg-12 col-xl-6 mb-1">
                <label>Facebook</label>
                <b-form-group>
                  <b-form-input
                    placeholder="Link do facebook"
                    for="text"
                    v-model="info.facebook"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-lg-12 col-xl-6 mb-1">
                <label>Twitter</label>
                <b-form-group>
                  <b-form-input
                    placeholder="Link do Twitter"
                    for="text"
                    v-model="info.twitter"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-lg-12 col-xl-6 mb-1">
                <label>Instagram</label>
                <b-form-group>
                  <b-form-input
                    placeholder="Link do Instagram"
                    for="text"
                    v-model="info.instagram"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-lg-12 col-xl-6 mb-1">
                <label>YouTube</label>
                <b-form-group>
                  <b-form-input
                    placeholder="Link do YouTube"
                    for="text"
                    v-model="info.youtube"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-lg-12 col-xl-12 mb-1">
                <label>Whatsapp</label>
                <b-form-group>
                  <b-form-input
                    placeholder="Número do whatsapp"
                    for="text"
                    v-model="info.whatsapp"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-lg-12 mb-1">
                <label>Descrição Curta</label>
                <b-form-group>
                 <b-form-textarea
                    id="textarea"
                    placeholder="Descrição..."
                    rows="6"
                    max-rows="6"
                    v-model="info.description"
                  ></b-form-textarea>
                </b-form-group>
              </div>
              <div class="col-12 my-3 text-right">
                <b-button variant="success" @click.prevent="salvarDados()">
                  <i class="bx bx-save font-size-16 align-middle mr-2"></i>
                  Salvar
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-card-text>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  components: {},
  data() {
    return {
      info: null,
      loading: true,
      error: false,
    }
  },
  created() {
    this.listInfo()
  },
  methods: {
    ...mapActions('appearance', ['ActionInfoFind', 'ActionInfoUpdate']),
    async salvarDados() {
      try {
        await this.ActionInfoUpdate(this.info)
        this.$swal({
          title: 'Sucesso',
          text: 'Ação realizada com sucesso!',
          icon: 'success',
          timer: 2000,
          timerProgressBar: true,
        })
      } catch (e) {
        this.$swal({
          title: 'Erro!',
          text: "Não foi possível realizar o cadastro!",
          icon: 'error',
          timer: 2000,
          timerProgressBar: true,
        })
      }
    },
    async listInfo() {
      try {
        this.loading = true
        await this.ActionInfoFind().then((res) => {
          this.info = res.data.data
          this.loading = false
          this.error = false
        })
      } catch (e) {
        this.info = null
        this.loading = false
        this.error = true
      }
    },
  },
}
</script>
